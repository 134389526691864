<template>
  <div class="Category">
    <v-card class="mx-auto" max-width="1170">
      <v-toolbar color="orange" dark>
        <v-toolbar-title>製品一覧</v-toolbar-title>
      </v-toolbar>
      <p>調べたい製品を選択してください。</p>
      <v-list>
        <v-list-item-group>
          <v-list-item :to="{ name: 'Top' }">
            <v-list-item-content>トップに戻る</v-list-item-content>
          </v-list-item>
          <v-list-item :to="{ name: 'Category' }">
            <v-list-item-content>カテゴリ一覧に戻る</v-list-item-content>
          </v-list-item>
          <v-list-item :to="{ name: 'Manufacturer', params: { categorySlug: $route.params.categorySlug } }">
            <v-list-item-content>製造メーカー一覧に戻る</v-list-item-content>
          </v-list-item>
          <v-list-item :to="{ name: 'Brand', params: { categorySlug: $route.params.categorySlug, manufacturerSlug: $route.params.manufacturerSlug } }">
            <v-list-item-content>ブランド一覧に戻る</v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card>
    <v-card class="mx-auto" max-width="1170">
      <v-toolbar color="orange" dark>
        <v-toolbar-title>製品一覧</v-toolbar-title>
      </v-toolbar>
      <v-list two-line>
        <v-list-item-group>
          <template v-for="product in products">
            <v-list-item :key="product.id" :to="{ name: 'Product', params: { productId: product.id } }">
              <template>
                <v-list-item-content>
                  <v-list-item-title v-text="product.name"></v-list-item-title>
                  <v-list-item-subtitle v-text="product.updated"></v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-list-item>
          </template>
        </v-list-item-group>
      </v-list>
    </v-card>
  </div>
</template>


<script>
const axios = require('axios')

export default{
  name: 'Product',
  components: {
  },
  data () {
    return {
      products: null
    }
  },
  mounted() {
    const categorySlug = this.$route.params.categorySlug
    const manufacturerSlug = this.$route.params.manufacturerSlug
    const brandSlug = this.$route.params.brandSlug
    axios.get('https://api.pc-combo.tokyo/categories/' + categorySlug + '/' + manufacturerSlug + '/' + brandSlug + '.json')
        .then(response => (this.products = response.data.products))
        .catch(error => console.log(error))
  }
}
</script>
